import Helmet from "react-helmet"
import React from "react"
import FirstRun from "src/components/firstrun"
import HomepageLayout from "src/layouts/homepage"
import {isIeEdge,isChrome,isFirefox} from "@tightrope/lpscripts/browserdetect"
import firstRunData from "src/pages/firstrun/data/firstRunData.json"

export default function FirstRunPagegit () {

  return(
    <HomepageLayout>
      <Helmet>
        <style type="text/css">{`


            .footerlinkssingle-module--br {
              margin: 0;
              background: #EDF1FA;
              padding: 10px;
            }


          `}</style>
      <title>Easy Print</title></Helmet>
      <FirstRun data={firstRunData}>

      </FirstRun>
    </HomepageLayout>
  )
}
